import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../../../components/UI/Loading";
import { Detail } from "../../../../../services/reportsSocialSecurityInfoGet";
import { fetchSocialSecurityInfo as fetchSocialSecurityInfoAction } from "../../../../../store/socialSecurity/actions";
import { SocialSecurityState } from "../../../../../store/socialSecurity/types";
import { AppState } from "../../../../../store/store";
import Message from "../Message";
import SocialSecurityDetails from "./SocialSecurityDetails";

interface Props {
  socialSecurityState: SocialSecurityState;
  fetchSocialSecurityInfo(): void;
}

const SocialSecurity = ({ socialSecurityState, fetchSocialSecurityInfo }: Props) => {
  const [details, setDetails] = useState<Detail[]>([]);

  useEffect(() => {
    fetchSocialSecurityInfo();
  }, []);

  useEffect(() => {
    if (socialSecurityState.status === "loaded") {
      const { details } = socialSecurityState.payload;
      setDetails(details);
    }
  }, [socialSecurityState.status]);

  if (socialSecurityState.status === "loading")
    return <Loading />

  if (socialSecurityState.status === "loaded") {
    if (!details.length) {
      return (
        <Message
          title="No hay aportes recientes"
          description="No tienes aportes de seguridad social en los últimos 3 meses para mostrar."
        />
      )
    }
    return <SocialSecurityDetails details={details} />
  }

  return <></>
}

const mapStateToProps = (state: AppState) => ({
  socialSecurityState: state.socialSecurityInfo
});

const mapDispatchToProps = {
  fetchSocialSecurityInfo: fetchSocialSecurityInfoAction
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialSecurity);