import Footprint from "../../types/report/Footprint";
import { FootprintsAction } from "./types";

export const fetchFootprints = (): FootprintsAction => ({
  type: "footprints/FETCH_INFO"
});

export const setFetchFootprintsSucceeded = (
  footprints: Footprint[]
): FootprintsAction => ({
  type: "footprints/FETCH_INFO_SUCCEEDED",
  footprints,
});

export const setFetchFootprintsFailed = (error: any): FootprintsAction => ({
  type: "footprints/FETCH_INFO_FAILED",
  error
});
