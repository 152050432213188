import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PlanResponse } from "../../services/plansGet";
import { fetchPlans as fetchPlansAction } from "../../store/plans/action";
import { PlansState } from "../../store/plans/types";
import { AppState } from "../../store/store";
import Plans from "../../views/Plans/components/Plans";
import Loading from "../UI/Loading";
import styles from "./NoSubscription.module.scss";

interface Props {
  title?: string;
  plansState: PlansState;
  fetchPlans(): void;
}

const NoSubscription = ({
  title,
  plansState,
  fetchPlans,
}: Props) => {
  const [plans, setPlans] = useState<PlanResponse[]>([]);

  useEffect(() => {
    fetchPlans();
  }, []);

  useEffect(() => {
    if (plansState.status === "loaded") {
      const plans = plansState.payload;
      setPlans(plans);
    }
  }, [plansState]);

  return (
    <div className={styles.container}>
      <div className={`eb-card ${!title ? styles.hidden : ""}`}>
        <div className={styles.content}>
          {title && (
            <p className={styles.title}>
              {title}
            </p>
          )}
          <div>
            <p className={styles.title}>
              Nuestros planes
            </p>
            <p className={styles.description}>
              Escoge el plan que más se ajuste a tus metas financieras:
            </p>
          </div>
          <div className={styles.inner}>
            {plansState.status === "loading" && <Loading />}
            <Plans plans={plans} small />
          </div>
        </div>
      </div>
      <div className={styles.outer}>
        {plansState.status === "loading" && <Loading />}
        <Plans plans={plans} small />
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    plansState: state.plans,
  };
};

const mapDispatchToProps = {
  fetchPlans: fetchPlansAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoSubscription);