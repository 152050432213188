import React from "react";
import Icon from "../../../../../components/UI/Icon";
import { formatDate } from "../../../../../helpers/date";
import { formatMoney } from "../../../../../helpers/money";
import { Detail } from "../../../../../services/reportsSocialSecurityInfoGet";
import styles from "./SocialSecurityDetail.module.scss";

interface Props extends Detail { }

const SocialSecurityDetail = ({ period, baseIncome, contributorDetail }: Props) => {
  return (
    <div className={styles.period}>
      <div className={styles.shape}>
        <Icon
          icon="verified_user"
          className={styles.icon}
        />
      </div>
      <div className={styles.details}>
        <p className={styles.date}>
          {formatDate(period, { in: undefined, out: "MMMM YYYY" })}
        </p>
        <p className={styles.text}>
          {contributorDetail}
        </p>
        <p className={styles.text}>
          <span>
            Salario base:
          </span>
          &nbsp;
          {formatMoney(baseIncome)}
        </p>
      </div>
    </div>
  )
}

export default SocialSecurityDetail;