import React from "react";
import Page from "../../components/Layout/Page";
import IdentityValidationContainer from "./containers/IdentityValidationContainer";

const IdentityValidationView = () => {
  return (
    <Page>
      <div className="eb-card">
        <IdentityValidationContainer />
      </div>
    </Page>
  )
}

export default IdentityValidationView;