export type VideoIDError =
  | 'VideoID.MaxLengthExceeded'
  | 'VideoID.ConnectingError'
  | 'VideoID.NotSupported'
  | 'Microphone.NotFound'
  | 'Microphone.PermissionDenied'
  | 'Camera.NotFound'
  | 'Camera.PermissionDenied'
  | 'Camera.Unavailable'
  | 'Connection.Lost'
  | 'RTCConnection.NotAvailable'
  | 'RTCConnection.Error'
  | 'IdType.NotSupportedInClient'
  | 'Luminance.TooLow'
  | 'Luminance.TooHigh'
  | 'Bandwidth.TooLow'
  | 'Image.Blurred'
  | 'FacesMismatch.Detected'
  | 'Faces.MultipleFaces'
  | 'DocumentSides.DataMismatch'
  | 'DocumentSides.Mismatch'
  | 'Document.Illegible'
  | 'Document.Illegitimate'
  | 'Document.NotAllowed'
  | 'Document.WrongSide'
  | 'UserConnection.Aborted'
  | 'AgentConnection.Aborted'
  | 'VideoID.AgentWaitTimeout'
  | 'VideoID.RejectedByAgent'
  | 'Permissions.Refused'
  | 'Service.NotAvailable'
  | 'AccessToken.Invalid'
  | 'VideoID.InvalidIDType'
  | 'VideoID.BadRequest'
  | 'VideoID.Error';

// NOTE: this interface is just for documentation
interface VideoIDFailed {
  error: {
    errorId: string;
    date: number;
    // NOTE: this field is the only important
    error: VideoIDError;
  };
  notification: {
    name: string;
    action: 'error';
    type: 'text';
    ttl: number;
    message: { text: string };
  };
}

export interface VideoIDCompleted {
  id: string;
}

export interface Video {
  start: () => Promise<string>;
  stop: () => {};
}

export const getVideoID = (videoIDToken: string, container: string) => {
  const videoId = (window as any).EID.videoId(`#${container}`, { lang: 'es' });
  // ID TYPES: https://etrust-sandbox.electronicid.eu/v2/videoid.idtypes
  const ColombiaIDType = '214';

  const start: Video['start'] = () => {
    videoId.turnOn();
    videoId.start({
      authorization: videoIDToken,
      idType: ColombiaIDType
    });

    return new Promise((resolve, reject) => {
      videoId.on('completed', ({ id }: VideoIDCompleted) => {
        videoId.turnOff();
        resolve(id);
      });

      videoId.on('failed', (video: VideoIDFailed) => reject(video.error.error));
    });
  };

  return {
    start,
    stop: videoId.turnOff
  };
};
export const appendVideoIDScript = async (onLoad: () => Promise<void>) => {
  if (document.getElementById('electronic-id-script')) {
    await onLoad();
    return;
  }

  const videoIDSource = process.env.REACT_APP_ELECTRONIC_ID!;

  const script = document.createElement('script');
  script.id = 'electronic-id-script';
  script.async = true;
  script.src = videoIDSource;
  script.onload = onLoad;
  document.body.appendChild(script);
  return script;
};
