import { Reducer } from "redux";
import { AlertsAction, AlertsState } from "./types";

const initialState: AlertsState = {
  status: "init"
};

const reducer: Reducer<AlertsState, AlertsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "alerts/FETCH_INFO":
      if (state.status === "loaded") {
        return {
          ...state,
          // No updates this information
          // status: "updating"
        };
      } else {
        return {
          status: "loading"
        };
      }
    case "alerts/FETCH_INFO_SUCCEEDED":
      return {
        status: "loaded",
        payload: action.alerts
      };
    case "alerts/FETCH_INFO_FAILED":
      return {
        status: "error",
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
