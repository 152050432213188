import { ScoresResponse } from "../../services/scoresGet";
import { ScoresAction } from "./types";

export const fetchScore = (): ScoresAction => ({
  type: "scores/FETCH_INFO"
});

export const setFetchInfoSucceeded = (
  scores: ScoresResponse
): ScoresAction => ({
  type: "scores/FETCH_INFO_SUCCEEDED",
  scores
});

export const setFetchInfoFailed = (error: any): ScoresAction => ({
  type: "scores/FETCH_INFO_FAILED",
  error
});
