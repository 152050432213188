import { UIAction } from './types';

export const showWppButton = (show: boolean): UIAction => ({
  type: 'UI/SHOW_WPP_BUTTON',
  show
});

export const setHasPermissions = (hasPermissions: boolean): UIAction => ({
  type: 'UI/SET_HAS_PERMISSIONS',
  hasPermissions
});

export const showLogOutValidation = (show: boolean): UIAction => ({
  type: 'UI/SHOW_LOG_OUT_VALIDATION',
  show
});

export const clearStore = (): UIAction => ({
  type: 'UI/CLEAR_STORE'
})
