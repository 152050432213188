import React from "react";
import isSubscribed from "../../../../../helpers/isSubscribed";
import SocialSecurity from "./SocialSecurity";
import SocialSecurityFree from "./SocialSecurityFree";

const SocialSecurityContainer = () => {
  const userIsSubscribed = isSubscribed();

  return userIsSubscribed
    ? <SocialSecurity />
    : <SocialSecurityFree />
}

export default SocialSecurityContainer;