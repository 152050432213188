import { Reducer } from "redux";
import { FootprintsAction, FootprintsState } from "./types";

const initialState: FootprintsState = {
  status: "init"
};

const reducer: Reducer<FootprintsState, FootprintsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "footprints/FETCH_INFO":
      if (state.status === "loaded") {
        return {
          ...state,
          // No update this information
          // status: "updating"
        };
      } else {
        return {
          status: "loading"
        };
      }
    case "footprints/FETCH_INFO_SUCCEEDED":
      return {
        status: "loaded",
        payload: action.footprints
      };
    case "footprints/FETCH_INFO_FAILED":
      return {
        status: "error",
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
