import { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { track } from '../helpers/cio';
import isLogged from '../helpers/isLogged';
// import usersTracingPost from '../services/usersTracingPost';

const TrackUser = ({ location }: RouteComponentProps) => {
  useEffect(() => {
    const path = (window as any).location.origin + location.pathname + location.search;
    // usersTracingPost({ url: path }).catch(error => {});
    if (isLogged()) {
      track("page-view", { url: path });
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(TrackUser as FunctionComponent<RouteComponentProps>);
