import React, { useState } from "react";
import { Tooltip as RTooltip, TooltipProps } from "reactstrap";

interface Props {
  children: React.ReactNode;
  target: string | HTMLElement;
  delay?: number;
  autohide?: boolean;
  className?: string;
  placement?: TooltipProps["placement"];
}

const Tooltip = ({ children, target, delay, autohide, className, placement }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <RTooltip
      delay={delay}
      isOpen={open}
      target={target}
      autohide={autohide}
      placement={placement}
      className={className}
      toggle={() => setOpen(!open)}
    >
      {children}
    </RTooltip>
  )
}

export default Tooltip