// id and uuid are the same value (Enbanca uuid)
// id is primary key in cio
// uuid is  an attribute of a person in cio
export interface Person {
  id: string;
  uuid: string;
  email: string;
  phone: string;
  device_id: string;
  last_name: string;
  first_name: string;
}

interface Data {
  [key: string]: any;
}

export const identify = (person: Person) => {
  const cio = (window as any)._cio;
  if (cio && cio.identify) {
    cio.identify({
      ...person,
      last_identify: Math.round(Date.now() / 1000)
    })
  }
}

export const track = (event: string, data: Data = {}) => {
  const cio = (window as any)._cio;
  if (cio && cio.track) {
    cio.track(event, data);
  }
}
