import api from "./api";

interface Response {
  payload: {
    token: string
  };
}

const url = 'eid/token';

const eidTokenGet = async (): Promise<Response> => {
  try {
    return await api.get(url, true);
  } catch (error) {
    return { payload: { token: "" } };
  }
}

export default eidTokenGet;