import React from "react";
import HeaderValidation from "../components/HeaderValidation";
import Interview from "../components/Interview";
import styles from "./ValidationContainerInterview.module.scss";

const ValidationContainerInterview = () => {
  return (
    <div className={styles.container}>
      <HeaderValidation
        message="Por motivos de seguridad debemos realizar algunas validaciones adicionales."
      />
      <Interview />
    </div>
  )
}

export default ValidationContainerInterview;