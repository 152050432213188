import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../../../components/UI/Loading";
import { formatDate } from "../../../../../helpers/date";
import { ReportSummaryState } from "../../../../../store/reportSummary/types";
import { AppState } from "../../../../../store/store";
import Message from "../Message";
import { Detail } from "./SocialSecurityFreeDetail";
import SocialSecurityFreeDetails from "./SocialSecurityFreeDetails";

interface Props {
  reportSummaryState: ReportSummaryState;
}

const SocialSecurityFree = ({ reportSummaryState }: Props) => {
  const [details, setDetails] = useState<Detail[]>([]);

  useEffect(() => {
    if (reportSummaryState.status === "loaded") {
      const { payload } = reportSummaryState;
      const { nbqInfo } = payload;
      if (nbqInfo) {
        const { empLast3M, typeOfQuote, lastQuoteDate } = nbqInfo;
        // const date = new Date(+lastQuoteDate.slice(0, 4), +lastQuoteDate.slice(4) - 1);
        const date = formatDate(lastQuoteDate, { in: undefined, out: "MMMM YYYY" });
        const details: Detail[] = [
          { label: "Última fecha", value: [date], tooltip: { id: "date", message: "Última fecha de cotización" } },
          { label: "Períodos", value: [`${empLast3M}`], tooltip: { id: "periods", message: "Número de períodos cotizados" } },
          { label: "Tipo de cotizante", value: typeOfQuote },
          {
            label: "Importante",
            value: [
              "Esta información solo tiene en cuenta los últimos 3 meses.",
              "La fecha de corte para reportar la seguridad social es hasta el día 16 de cada mes.",
            ],
            note: true
          }
        ]
        setDetails(details);
      } else {
        setDetails([]);
      }
    }
  }, [reportSummaryState.status]);

  if (reportSummaryState.status === "loading")
    return <Loading />

  if (reportSummaryState.status === "loaded" || reportSummaryState.status === "updating") {
    if (!details.length) {
      return (
        <Message
          title="No tienes un plan activo"
          description="No puedes ver esta información porque no tienes un plan activo. ¡Descubre las últimas actualizaciones adquiriendo tu plan!"
        />
      )
    }
    return <SocialSecurityFreeDetails details={details} />
  }

  return <></>
}

const mapStateToProps = (state: AppState) => ({
  reportSummaryState: state.reportSummary,
});

export default connect(mapStateToProps)(SocialSecurityFree);
