import captureMessage from "./captureMessage";
interface ResponseValidation {
  valid: boolean;
  exceededAttemps: boolean;
}

interface ResponseNameValidation extends ResponseValidation {
  found: boolean;
}

// The user has 5 attempts for validation
const MAX_ATTEMPS = 5;

export const initZipps = async () => {
  const zippsScript = document.createElement("script");
  zippsScript.onload = async () => {
    try {
      const zipps = (window as any).zipps;
      if (zipps) {
        await (window as any).zipps.init();
        // This Sentry Message is for monitoring "firmographic" call in Zipps
        if (zipps.firmographicError)
          captureMessage("Zipps: Firmographic data failed");
      }
    } catch (error) {
      console.error("Zipps | init failed");
    }
  };
  zippsScript.src =
    process.env.REACT_APP_ZIPPS_CDN || "https://playcdn.zipps.in/v2/index.js";
  document.head.appendChild(zippsScript);
};

export const validateName = async (
  document: string,
  documentType: string,
  firstName: string,
  lastName: string
) => {
  let response: ResponseNameValidation = {
    valid: true,
    exceededAttemps: false,
    found: true
  };
  const zipps = (window as any).zipps;
  if (zipps) {
    try {
      const valid = await zipps.validateName(
        document,
        documentType,
        firstName,
        lastName
      );
      response.found = !!valid.found;
      // response.valid = (valid.status || '').toLowerCase() === 'match';
      response.valid = (valid.matchScore || 0) >= 75 || !valid.found;
      response.exceededAttemps = (valid.count as number) >= MAX_ATTEMPS;
    } catch {}
  }
  return response;
};

export const validateEmail = async (email: string) => {
  let response: ResponseValidation = { valid: true, exceededAttemps: false };
  const zipps = (window as any).zipps;
  if (zipps) {
    try {
      const valid = await zipps.validateEmail(email);
      response.valid = (valid.status || "").toLowerCase() === "valid";
      response.exceededAttemps = (valid.count as number) >= MAX_ATTEMPS;
    } catch {}
  }
  return response;
};

export const validatePhone = async (phone: string) => {
  let response: ResponseValidation = { valid: true, exceededAttemps: false };
  const zipps = (window as any).zipps;
  if (zipps) {
    try {
      const valid = await zipps.validatePhone(phone);
      const isValid = (valid.status || "").toLowerCase() === "valid";
      const hasWhatsapp = valid.wp as boolean;
      response.valid = isValid && hasWhatsapp;
      response.exceededAttemps = (valid.count as number) >= MAX_ATTEMPS;
    } catch {}
  }
  return response;
};

export const updateMetadata = async (metatada: Object) => {
  const zipps = (window as any).zipps;
  if (zipps) {
    try {
      await zipps.update(metatada);
    } catch (error) {
      console.error("Zipps | update failed");
    }
  }
};

export const getDeviceId = async () => {
  const zipps = (window as any).zipps;
  if (zipps) {
    try {
      return (await ((zipps.device || {}).deviceId || "")) as string;
    } catch (error) {
      return "";
    }
  }
  return "";
};
