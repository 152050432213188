import * as Sentry from "@sentry/browser";
import { put, takeLeading } from "redux-saga/effects";
import { identify } from "../../helpers/cio";
import { getDeviceId } from "../../helpers/zipps";
import usersMeGet, { UserInfoResult } from "../../services/usersMeGet";
import store from "../store";
import { setFetchInfoFailed, setFetchInfoSucceeded } from "../user/actions";

function* fetchUsersMe() {
  try {
    const userInfoResult = yield usersMeGet();
    const user = (userInfoResult.payload as UserInfoResult).user;
    yield put(setFetchInfoSucceeded(user));
    const uuid = store.getState().security.userId || "";
    const userData = {
      id: uuid,
      uuid,
      email: user.email,
      phone: user.phone,
      first_name: user.name,
      last_name: user.lastname
    };
    // Sentry User
    Sentry.setUser(userData);
    // Customer.io identify user
    identify({ ...userData, device_id: yield getDeviceId() });
  } catch (error) {
    yield put(setFetchInfoFailed());
    return;
  }
}

export default function* watchFetchUserInfo() {
  yield takeLeading("user/FETCH_INFO", fetchUsersMe);
}
