import React, { useState } from "react";
import Alert from "reactstrap/lib/Alert";
import InputField from "../../../components/Form/InputField";
import RadioButtonsField from "../../../components/Form/RadioButtonsField";
import ErrorMessages from "../../../components/UI/ErrorMessages";
import formatOnlyNumbers from "../../../helpers/formatOnlyNumbers";
import { validateName } from "../../../helpers/zipps";

interface Props {
  documentType: string;
  document: string;
  name: string;
  lastname: string;
  setIsValidated: (value: boolean) => void
  setIsValidating: (value: boolean) => void
}

const NameValidation = ({ documentType, document, name, lastname, setIsValidated, setIsValidating }: Props) => {
  const errorMessage = "Los nombres y apellidos no coinciden con el documento de identidad";
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [found, setFound] = useState(true);

  const [lastValues, setLastValues] = useState({
    documentType: "",
    document: "",
    name: "",
    lastname: "",
  });
  const handleBlur = async () => {
    setLastValues({ document, documentType, name, lastname });
    if (documentType && document && name && lastname) {
      if (
        documentType !== lastValues.documentType ||
        document !== lastValues.document ||
        name !== lastValues.name ||
        lastname !== lastValues.lastname
      ) {
        setIsValidated(false);
        setIsValidating(true);
        setLastValues({ document, documentType, name, lastname });
        const { valid, exceededAttemps, found } = await validateName(document, documentType, name, lastname);
        setFound(found);
        setDisabled(exceededAttemps)
        setError(!valid);
        setIsValidating(false);
        setIsValidated(valid);
      }
    } else {
      setIsValidated(false);
    }
  }
  return (
    <>
      <RadioButtonsField
        name="documentType"
        label="Tipo de documento de identidad"
        options={[
          { label: "Cédula de ciudadanía", value: "CC" },
          { label: "Cédula de extranjería", value: "CE" }
        ]}
      />
      <div onBlur={handleBlur}>
        <InputField
          name="document"
          label="Documento de identidad"
          formatValue={formatOnlyNumbers}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>
      <Alert color="primary">
        Recuerda que debes ser el titular del documento que estás
        ingresando.
      </Alert>
      <div onBlur={handleBlur}>
        <InputField
          name="name"
          label="Nombres"
          description="Debes ingresar tus nombres tal como aparece en tu documento de identidad"
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>
      <div onBlur={handleBlur}>
        <InputField
          name="lastname"
          label="Apellidos"
          description="Debes ingresar tus apellidos tal como aparecen en tu documento de identidad"
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>
      {error && <ErrorMessages errorMessages={[errorMessage]} />}
      {!found && (
        <Alert color="warning">
          Verifica el documento ingresado
        </Alert>
      )}
    </>
  )
}

export default NameValidation;
