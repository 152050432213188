import { SocialSecurityResponse } from "../../services/reportsSocialSecurityInfoGet";
import { SocialSecurityAction } from "./types";

export const fetchSocialSecurityInfo = (): SocialSecurityAction => ({
  type: "socialSecurity/FETCH_INFO"
});

export const setFetchInfoSucceeded = (
  socialSecurityInfo: SocialSecurityResponse
): SocialSecurityAction => ({
  type: "socialSecurity/FETCH_INFO_SUCCEEDED",
  socialSecurityInfo
});

export const setFetchInfoFailed = (error: any): SocialSecurityAction => ({
  type: "socialSecurity/FETCH_INFO_FAILED",
  error
});
