import React from "react";
import { Detail } from "../../../../../services/reportsSocialSecurityInfoGet";
import SocialSecurityDetail from "./SocialSecurityDetail";
import styles from "./SocialSecurityDetails.module.scss";

interface Props {
  details: Detail[];
}

const SocialSecurityDetails = ({ details }: Props) => {
  return (
    <div className="eb-card">
      <div className={styles.container}>
        <p className={styles.title}>
          Tus últimos aportes de seguridad social
        </p>
        <div className={styles.content}>
          {details.map((detail, i) => (
            <SocialSecurityDetail key={`${detail.period}-${i}`} {...detail} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SocialSecurityDetails;