export const getSandboxAuthorization = () => {
  return new Promise(resolve => {
    // XMLHttpRequest is used here to bypass fetchMock
    // It's only for testing, not for production
    const request = new XMLHttpRequest();
    request.addEventListener('load', (result: any) =>
      resolve(JSON.parse(result.currentTarget.response))
    );
    request.open(
      'POST',
      'https://etrust-sandbox.electronicid.eu/v2/videoid.request'
    );
    request.setRequestHeader(
      'Authorization',
      'Bearer cecca9f3-4a31-4656-9693-cb7f419ae16b'
    );
    request.setRequestHeader('Content-Type', 'application/json');
    request.send(JSON.stringify({}));
  });
};