import React from "react";
import SocialSecurityFreeDetail, { Detail } from "./SocialSecurityFreeDetail";
import styles from "./SocialSecurityFreeDetails.module.scss";

interface Props {
  details: Detail[];
}

const SocialSecurityFreeDetails = ({ details }: Props) => {
  return (
    <div className="eb-card">
      <div className={styles.container}>
        <p className={styles.title}>
          Seguridad social
        </p>
        <div className={styles.content}>
          {details.map(detail => (
            <SocialSecurityFreeDetail key={detail.label} {...detail} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SocialSecurityFreeDetails;
