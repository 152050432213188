import { AlertsResponse } from "../../services/alertsGet";
import { AlertsAction } from "./types";

export const fetchAlerts = (): AlertsAction => ({
  type: "alerts/FETCH_INFO"
});

export const setFetchAlertsSucceeded = (alerts: AlertsResponse): AlertsAction => ({
  type: "alerts/FETCH_INFO_SUCCEEDED",
  alerts
});

export const setFetchAlertsFailed = (error: any): AlertsAction => ({
  type: "alerts/FETCH_INFO_FAILED",
  error
});