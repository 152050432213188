import React, { useEffect } from "react";
import { getSandboxAuthorization } from "../../../helpers/eid";
import {
  Video,
  appendVideoIDScript,
  getVideoID,
} from "../../../helpers/videoID";
import { ElectronicIDAutorizationResponse } from "../../../services/electronicIDAutorizationGet";
import styles from "./ElectronicIDModal.module.scss";

interface Props {
  token: string;
  onValidationFailed: (error: any) => void;
  onValidationSuccess: (videoId: string) => void;
}

const ElectronicIDModal = ({ token, onValidationFailed, onValidationSuccess }: Props) => {

  useEffect(() => {
    let video: Video | null = null;

    const startVideo = async () => {
      try {
        if (process.env.NODE_ENV === "production") {
          video = getVideoID(token, "video-container");
        } else {
          const eidResponse = (await getSandboxAuthorization()) as ElectronicIDAutorizationResponse;
          video = getVideoID(eidResponse.authorization, "video-container");
        }
        const videoId = await video.start();
        onValidationSuccess(videoId);
      } catch (error) {
        onValidationFailed(error);
      }
    };
    appendVideoIDScript(startVideo);

    return () => {
      video && video.stop && video.stop();
    };
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.background} />
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>
            Validación de identidad
          </p>
          <p className={styles.text}>
            Este proceso es necesario para corroborar tu identidad
            personal y proteger tu información.
          </p>
          <div
            className={`spinner-border text-primary ${styles.spinner}`}
            role="status"
          />
        </div>
        <div id="video-container" className={styles.video} />
      </div>
    </div>
  );
};

export default ElectronicIDModal;
