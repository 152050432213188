import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { track } from "../../../helpers/cio";
import jwt from "../../../helpers/jwt";
import refreshToken from "../../../helpers/refreshToken";
import eidTokenGet from "../../../services/eidTokenGet";
import preferencesAccountEIDPost from "../../../services/preferencesAccountEIDPost";
import { showWppButton as showWppButtonAction } from "../../../store/UI/actions";
import ElectronicIDModal from "../components/ElectronicIDModal";
import FailedValidation from "../components/FailedValidation";
import HeaderValidation from "../components/HeaderValidation";
import PermissionsValidation from "../components/PermissionsValidation";
import SavingValidation from "../components/SavingValidation";
import styles from "./IdentityValidationContainer.module.scss";

interface Props extends RouteComponentProps {
  showWppButton: (show: boolean) => void;
}

type Step = "permissions" | "eid" | "eid-failed" | "validating";

const IdentityValidationContainer = ({ history, showWppButton }: Props) => {
  const [token, setToken] = useState("");
  const [step, setStep] = useState<Step>("permissions");

  useEffect(() => {
    showWppButton(false);
    getEidToken();
  }, [])

  const getEidToken = async () => {
    try {
      const response = await eidTokenGet();
      setToken(response.payload.token)
    } catch { }
  }

  const handlePermissions = (hasPermissions: boolean) => {
    if (hasPermissions) {
      setStep("eid");
      showWppButton(true);
    }
  };

  const handleElectronicIdSuccess = async (videoId: string) => {
    try {
      setStep("validating");
      await preferencesAccountEIDPost({ videoId });
      await refreshToken();
      track("eid-success");
      history.push("/dashboard");
    } catch (error) {
      setStep("eid-failed");
      track("eid-failed");
    }
  };

  const handleElectronicIdFailed = (error: any) => {
    preferencesAccountEIDPost({ error });
    setStep("eid-failed");
    track("eid-failed");
  };

  const handleTryAgain = async () => {
    try {
      const newToken = await refreshToken();
      const { eid } = jwt.decode(newToken);
      if (eid) setStep("eid");
      else {
        track("eid-validated");
        history.push("/dashboard");
      }
    } catch { }
  };


  return (
    <div className={styles.container}>
      <HeaderValidation
        message="Por motivos de seguridad, debemos validar tu identidad."
      />
      {step === "permissions" && <PermissionsValidation onPermisions={handlePermissions} />}
      {step === "eid" &&
        <ElectronicIDModal
          token={token}
          onValidationFailed={handleElectronicIdFailed}
          onValidationSuccess={handleElectronicIdSuccess}
        />
      }
      {
        step === "eid-failed" && <FailedValidation tryAgain={handleTryAgain} />
      }
      {step === "validating" && <SavingValidation />}
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    showWppButton: (show: boolean) => dispatch(showWppButtonAction(show)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withRouter(IdentityValidationContainer));