import React, { useState } from "react";
import { Button } from "reactstrap";
import ErrorMessages from "../../../components/UI/ErrorMessages";
import { track } from "../../../helpers/cio";
import { getNewTransactionEventData, redirectToPaymentGateway } from "../../../helpers/planTransaction";
import { PlanResponse } from "../../../services/plansGet";
import planTransactionPost from "../../../services/planTransactionPost";
import styles from "./Payment.module.scss";
import PaymentCode, { OnRedeemParams } from "./PaymentCode";
import PaymentGateway from "./PaymentGateway";
import PaymentSecurity from "./PaymentSecurity";
import PaymentSummary from "./PaymentSummary";

interface Props {
  plan: PlanResponse;
  onSuccess: (teaser: string) => void;
}

const Payment = ({ plan, onSuccess }: Props) => {
  const { uuid: planUuid, name, cost, isDefault } = plan;

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [value, setValue] = useState(cost);
  const [loading, setLoading] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState("");

  const handleSelected = (code: string) => setPaymentGateway(code);

  const handleRedeem = ({ code, teaser, value }: OnRedeemParams) => {
    setCode(code);
    setValue(value);
    // if the promotional code is 100%
    if (!value) onSuccess(teaser);
  }

  const handlePay = async () => {
    try {
      setError("");
      setLoading(true);
      const transaction = await planTransactionPost({ planUuid, paymentGateway, promoCode: code });
      const { payload } = transaction;
      if (payload) {
        // Customer event
        const data = { code, plan: name, gtw: paymentGateway, ...getNewTransactionEventData(paymentGateway, payload) };
        track("new-plan-transaction", data);
        // Redirect to Payment Geteway
        redirectToPaymentGateway(payload);
      }
    } catch {
      setError("Sucedió un error, inténtalo más tarde.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PaymentSecurity />
      <div className={styles.container}>
        <PaymentSummary
          name={name}
          code={code}
          value={value}
          isDefault={isDefault}
        />
        <PaymentGateway onSelected={handleSelected} />
        {!code && <PaymentCode planUuid={planUuid} onRedeem={handleRedeem} />}
        {error && <ErrorMessages errorMessages={[error]} />}
        <Button
          id="new-plan-transaction"
          onClick={handlePay}
          color="primary"
          style={{ marginTop: error ? "-1rem" : "" }}
          disabled={loading || !value || !paymentGateway}
        >
          {
            loading
              ? <div className="spinner-border spinner-border-sm" role="status" />
              : "Pagar"
          }
        </Button>
      </div>
    </>
  )
}

export default Payment;