import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import Icon from "../UI/Icon";
import styles from "./SidebarMenuContent.module.scss";

interface Props extends RouteComponentProps {
  onToggle?: () => void;
}

const SidebarMenuContent = ({ location, onToggle }: Props) => {
  const logo = require("../../images/logos/logo-enbanca-blue.png");
  const pages = [
    { to: "/dashboard", icon: "home", label: "Inicio" },
    { to: "/consultas", icon: "visibility", label: "¿Me han consultado?" },
    { to: "/alertas", icon: "notifications", label: "Mis alertas" },
    { to: "/planes", icon: "star", label: "Planes", className: styles.star },
  ]
  const externalPages = [
    { to: "https://enbanca.co/preguntas-frecuentes", icon: "help_center", label: "Preguntas frecuentes" },
    { to: "https://enbanca.co/educacion-financiera", icon: "menu_book", label: "Educación financiera" },
  ]

  return (
    <>
      <NavLink
        to={"/dashboard"}
        onClick={onToggle}
      >
        <img
          src={logo}
          alt="logo enbanca"
          className={styles.logo}
        />
      </NavLink>
      <ul className={styles.wrapper}>
        {pages.map(({ to, icon, label, className }) => (
          <li
            key={to}
            className={`${styles.element} ${location.pathname === to && styles.active}`}
          >
            <NavLink
              to={to}
              onClick={onToggle}
            >
              <div className={styles.content}>
                <Icon
                  icon={icon}
                  className={className || ""}
                  fill
                />
                <p className={styles.label}>
                  {label}
                </p>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
      <hr className={styles.hr} />
      <ul className={styles.wrapper}>
        {externalPages.map(({ to, icon, label }) => (
          <li
            key={to}
            className={styles.element}
          >
            <a
              href={to}
              target="_blank"
              onClick={onToggle}
            >
              <div className={styles.content}>
                <Icon icon={icon} fill />
                <p className={styles.label}>
                  {label}
                </p>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <ul className={`${styles.wrapper} mt-auto`}>
        <li
          className={`${styles.element} ${location.pathname === "contactanos" && styles.active}`}
        >
          <NavLink
            to="/contactanos"
            onClick={onToggle}
          >
            <div className={styles.content}>
              <Icon icon="headset_mic" fill />
              <p className={styles.label}>
                Contáctanos
              </p>
            </div>
          </NavLink>
        </li>
      </ul>
    </>
  );
}

export default withRouter(SidebarMenuContent);