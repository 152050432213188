import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Dispatch } from 'redux';
import { clearStore } from '../../store/UI/actions';
import { logout as logoutAction } from '../../store/security/actions';
interface Props {
  logout: () => void;
  clearStore: () => void;
}

const LogOutView = ({ logout, clearStore }: Props) => {
  useEffect(() => {
    logout();
    clearStore();
  }, [])
  return <Redirect to="/" />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(logoutAction()),
  clearStore: () => dispatch(clearStore())
});

export default connect(
  null,
  mapDispatchToProps
)(LogOutView);
