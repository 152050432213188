import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import isSubscribed from "../../../../../helpers/isSubscribed";
import { Score } from "../../../../../services/scoresGet";
import { ScoresState } from "../../../../../store/scores/types";
import { AppState } from "../../../../../store/store";
import Message from "../Message";
import Info from "./Info";
import Risk from "./Risk";

interface Props {
  scoresState: ScoresState;
}

const ScoreContainer = ({ scoresState }: Props) => {
  const userIsSubscribed = isSubscribed();
  const [scores, setScores] = useState<Score[]>([]);

  useEffect(() => {
    if (scoresState.status === "loaded") {
      const scores = scoresState.payload;
      setScores(scores.puntajes);
    }
  }, [scoresState.status]);

  if (userIsSubscribed) {
    return (
      <>
        <Info />
        <Risk scores={scores} />
      </>
    )
  }

  return (
    <Message
      title="No tienes un plan activo"
      description="No puedes ver esta información porque no tienes un plan activo. ¡Descubre las últimas actualizaciones adquiriendo tu plan!"
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  scoresState: state.scores,
});

export default connect(mapStateToProps)(ScoreContainer);