import React from "react";
import Icon from "../../../../../components/UI/Icon";
import Tooltip from "../../../../../components/UI/Tooltip";
import styles from "./SocialSecurityFreeDetail.module.scss";

export interface Detail {
  label: string;
  value: string[];
  note?: boolean;
  tooltip?: {
    id: string;
    message: string;
  };
}

interface Props extends Detail { }

const SocialSecurityFreeDetail = ({ label, value, note, tooltip }: Props) => {
  return (
    <div className={`${styles.container} ${note ? styles.note : ""}`}>
      {note && <Icon icon="info" className={styles.icon} />}
      <div className={styles.info}>
        <p className={styles.label}>
          {label}
        </p>
        <div className={styles.grid}>
          {value.map(val => (
            <p
              key={val}
              className={styles.value}
            >
              {val}
            </p>
          ))}
        </div>
      </div>
      {tooltip && (
        <>
          <Icon
            id={`ss-${tooltip.id}`}
            icon="info"
            className={styles.icon}
          />
          <Tooltip
            target={`ss-${tooltip.id}`}
            placement="left"
          >
            {tooltip.message}
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default SocialSecurityFreeDetail;